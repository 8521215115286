import { useAppDispatch, useAppSelector } from "hooks";
import { Container, Form, InputGroup } from "react-bootstrap";
import { showingAddCreditsSelector } from "selectors/uiSelectors";
import { RootState } from "store";
import styled from "styled-components";
import { CCircleFill, X } from "react-bootstrap-icons";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import uiSlice from "reducers/uiSlice";
import { Formik } from "formik";
import Label from "components/label/Label";
import TextInput from "components/textInput/TextInput";
import * as Yup from "yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "components/payment/Payment";
import { FormEvent, useEffect, useState } from "react";
import userSlice, { makePaymentSuccess } from "reducers/userSlice";
import MakePaymentRequest from "types/makePaymentRequest";
import Scrollbars from "rc-scrollbars";
import {
  makePaymentErrorSelector,
  makingPaymentSelector,
  mnakePaymentSuccessSelector,
} from "selectors/userSelectors";

const CloseButton = styled(PrimaryButton)`
  width: 30px;
  min-height: 30px;
  padding: 0;
`;

const StyledContainer = styled(Container)`
  position: fixed;
  z-index: 10;
  padding-top: 50px;
  bottom: 0;
  top: 50px;
  background: var(--secondary-bg);

  @media only screen and (max-width: 768px) {
    padding-top: 20px;
  }

  .rc-scrollbars-view {
    padding: 0 50px 20px;

    @media only screen and (max-width: 768px) {
      padding: 0 10px 20px;
    }
  }
`;

const StyledHeader = styled.h1`
  font-size: var(--font-3);
  display: flex;
  align-items: center;

  > span {
    flex: 1;
  }
`;

const Info = styled.h4`
  margin: 40px 0;
  font-size: var(--font-1);
`;

const LargerLabel = styled(Label)`
  font-size: var(--font-3);
  display: flex;
  align-items: center;

  > span {
    flex: 1;
  }
`;

const CreditsGroup = styled(InputGroup)`
  > span {
    background: var(--field-color);
    color: aliceblue;
    border-color: var(--field-color);
    border-right: solid 1px var(--secondary-bg);
    margin-right: 1px;
  }
`;

const Legend = styled.div`
  font-size: var(--font-2);
`;

const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_KEY"] ?? "");

const initialCredits = 200;

const pricePerCreditUsd = 0.02;

const AddCredits = () => {
  const [amount, setAmount] = useState(
    initialCredits * pricePerCreditUsd * 100
  );

  const dispatch = useAppDispatch();

  const showingAddCredits = useAppSelector((state: RootState) =>
    showingAddCreditsSelector(state)
  );

  const makingPayment = useAppSelector((state: RootState) =>
    makingPaymentSelector(state)
  );

  const makePaymentError = useAppSelector((state: RootState) =>
    makePaymentErrorSelector(state)
  );

  const makePaymentSuccess = useAppSelector((state: RootState) =>
    mnakePaymentSuccessSelector(state)
  );

  useEffect(() => {
    if (makePaymentSuccess) {
      dispatch(userSlice.actions.loadUser());
      dispatch(userSlice.actions.clearMakePaymentSuccess());
      handleClose();
    }
  }, [makePaymentSuccess]);

  const handleClose = () => {
    dispatch(uiSlice.actions.hideAddCredits());
  };

  if (!showingAddCredits) return null;

  const options = {
    mode: "payment",
    amount: amount,
    currency: "usd",
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          marginBottom: ".5rem",
          fontSize: "1.6rem",
        },
        ".Input": {
          border: "solid 1px #4a4a57",
          lineHeight: "24px",
          padding: ".375rem .75rem",
        },
        ".Input:focus": {
          boxShadow: "none",
          border: "solid 1px #757dcb",
        },
        ".Input--invalid": {
          boxShadow: "none",
          border: "solid 1px red",
        },
      },
      variables: {
        colorPrimary: "#757dcb",
        colorBackground: "#4a4a57",
        colorText: "#e5e5e5",
        gridRowSpacing: "24px",
      },
    },
  } as const;

  const schema = Yup.object({
    credits: Yup.number().min(initialCredits).required("Credits"),
    agree: Yup.string()
      .required()
      .oneOf(
        ["true"],
        "You must read and agree to the Terms and Conditions and Privacy Policy"
      ),
  });

  let initialValues = {
    credits: initialCredits,
    agree: "",
    confirmationToken: "",
  };

  const handleKeyUp = (isValid: boolean, values: any) => {
    if (values.credits >= initialCredits) {
      setAmount(Math.round(values.credits * pricePerCreditUsd * 100));
    }
  };

  const handleSubmit = async (values: any) => {
    dispatch(
      userSlice.actions.makePayment({
        creditsToPurchase: values.credits,
        confirmationToken: values.confirmationToken,
      } as MakePaymentRequest)
    );
  };

  return (
    <StyledContainer fluid={true}>
      <Scrollbars>
        <StyledHeader>
          <span>Add Credits</span>
          <CloseButton onClick={handleClose}>
            <X size={20} />
          </CloseButton>
        </StyledHeader>
        <Elements stripe={stripePromise} options={options}>
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {(props) => (
              <>
                <Info>
                  Add credits to your account to generate more content. Adding
                  credits will also remove the free account restrictions.
                </Info>
                <Form.Group className="mb-4">
                  <LargerLabel>
                    <span>Credits</span>{" "}
                    <Legend>1 credits is ${pricePerCreditUsd}USD</Legend>
                  </LargerLabel>
                  <CreditsGroup>
                    <InputGroup.Text id="basic-addon1">
                      <CCircleFill />
                    </InputGroup.Text>
                    <TextInput
                      id="credits"
                      className="form-control"
                      hasError={props.errors.credits !== undefined}
                      value={props.values.credits}
                      onKeyUp={(e) => {
                        handleKeyUp(props.isValid, props.values);
                      }}
                      onChange={props.handleChange}
                    />
                  </CreditsGroup>
                  <Info>
                    Must be at least {initialCredits} credits. Max account
                    balance is 10,000 credits.
                  </Info>
                </Form.Group>
                <Payment
                  paymentError={makePaymentError}
                  makingPayment={makingPayment}
                  handleAgreeChange={props.handleChange}
                  agreeError={
                    props.touched.agree && props.errors.agree !== undefined
                  }
                  agreeValue={props.values.agree}
                  chargeUsd={amount / 100}
                  onPayment={(
                    event: FormEvent<HTMLFormElement>,
                    confirmationToken: string
                  ) => {
                    props.values.confirmationToken = confirmationToken;
                    props.handleSubmit(event);
                  }}
                />
              </>
            )}
          </Formik>
        </Elements>
      </Scrollbars>
    </StyledContainer>
  );
};

export default AddCredits;
